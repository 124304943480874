<template>
    <div class="con">
        <div class="tabs">
            <div :class="{'active':isActive}"  @click="tofindCode">找回密码</div>
            <el-divider direction="vertical"></el-divider>
            <div :class="{'active':!isActive}" @click="Back">返回</div>
        </div>

        <div class="form-fincode">
            <input type="text" placeholder="请输入账号" class="input1" v-model.number.trim="fusername"/>
            <div class="mobcode-con">
                <input type="text" placeholder="手机验证码" class="input2" v-model.trim="fmobcode"/>
               <button class="btn" @click="getPhoneCode" :style="{color:timeflag?'#020202':'#ffffff',background:timeflag?'#DDDDDD':''}">{{buttonText}}</button>
            </div>
            <input type="text" placeholder="设置密码（6-15位数的密码）" class="input1" v-model.trim="fpassword"/>
            <input type="text" placeholder="再次设置密码（密码请保持一致）" class="input1" v-model.trim="fpasswordt"/>
            
            <div class="code-con">
                <input type="text" placeholder="图形验证码（看不清？点击右侧图片换一张）" class="input2" v-model.trim="fimagecode"/>
                <img :src="code_img" @click="updatacodeImg"/>
            </div>
            <div class="form-login" @click="saveCode">保存</div>
        </div>
    </div>
</template>
<script>
import {ref,onMounted,reactive,toRefs} from 'vue'
import {getData} from '@/utils/req'
import {useRouter} from 'vue-router'
import { ElMessage } from 'element-plus'
export default {
    setup(){
         let isActive = ref(true)
         let timeflag = ref(true)
         let buttonText = ref('获取验证码')
         let state= reactive({
            code_img:'',
            code:''
        })
        let findState = reactive({
            fusername:'',
            fmobcode:'',
            fpassword:'',
            fpasswordt:'',
            fimagecode:''
        })
         const router = useRouter()
         onMounted(()=>{
             updatacodeImg()
         })
         const tofindCode=()=>{
             isActive.value =true
         }
         const Back =()=>{
             router.push({path:'/login',query:{"selected":1}})
         }
         const updatacodeImg = ()=>{
            const data={
                "module": "app_pc",
                "action": "login",
                "m": "get_code"
            }
            getData(data).then(res=>{
                state.code_img = "https://admin.iseso.net//"+res.data.code_img
                state.code = res.data.code
            })
        }
        const saveCode =()=>{
            if(!findState.fusername){
                ElMessage({
                showClose: true,
                message: '用户名不能为空',
                type: 'error',
                duration:2000
                })
                return
            }
            if(!findState.fmobcode){
                ElMessage({
                showClose: true,
                message: '验证码不能为空',
                type: 'error',
                duration:2000
                })
                return
            }
            if(!findState.fpassword){
                ElMessage({
                showClose: true,
                message: '设置的密码不能为空',
                type: 'error',
                duration:2000
                })
                return
            }
            if(!findState.fpasswordt){
                ElMessage({
                showClose: true,
                message: '设置的密码不能为空',
                type: 'error',
                duration:2000
                })
                return
            }
            if(findState.fpasswordt !==findState.fpassword){
                ElMessage({
                showClose: true,
                message: '两次密码不一致',
                type: 'error',
                duration:2000
                })
                return
            }
            if(!findState.fimagecode){
                 ElMessage({
                showClose: true,
                message: '图形验证码不能为空',
                type: 'error',
                duration:2000
                })
                return
            }
            if(state.code.toLocaleLowerCase() !== findState.fimagecode.toLocaleLowerCase()){
                ElMessage({
                showClose: true,
                message: '验证码错误',
                type: 'error',
                duration:2000
                })
                return
            }

            const data = {
				"module":"app_pc",
				"action":"login",
				"m":"forgotpassword",
				"phone":findState.fusername, // 手机号码
				"keyCode":findState.fmobcode, // 验证码
				"password":findState.fpasswordt, // 重置密码
			}
            getData(data).then(res=>{
                if(res.code ===200){
                     ElMessage({
                    showClose: true,
                    message: '密码重置成功',
                    type: 'success',
                    duration:2000
                    })
                    router.push({path:'/login',query:{selected:1}})
                }
                else{
                    ElMessage({
                    showClose: true,
                    message: res.message,
                    type: 'error',
                    duration:2000
                    })
                }
            })
        }
        const countDown = (i)=>{
            i--;
            console.log(i)
            if(i>0){
                buttonText.value = `${i}s后可重获`
                setTimeout(()=>{
                    countDown(i)
                },1000)
            }
            else{
                timeflag.value = true
                buttonText.value = '获取验证码'
            }

        }
        const getPhoneCode =()=>{
            if(!timeflag.value){
                return
            }

            if(!findState.fusername){
                ElMessage({
                    showClose: true,
                    message: '手机号不能为空',
                    type: 'error'
                })
                return;
            }
            timeflag.value = false

            const data={
                "module": "app",
                "action": "user",
                "app": "secret_key",
                "phone":findState.fusername
            }
            getData(data).then(res=>{
                if(res.code ===200){
                    countDown(60)
                    ElMessage({
                    showClose: true,
                    message: '发送成功',
                    type: 'success'
                    })
                }
                else if(res.code == 220){
					timeflag.value = false
                    ElMessage({
                    showClose: true,
                    message: '短信发送频率超限！',
                    type: 'error'
                    })
					
				}else{
					timeflag.value = false
					ElMessage({
                    showClose: true,
                    message: res.message,
                    type: 'error'
                })
				}
            })
            
        }
         return{
             isActive,
             timeflag,
             buttonText,
             ...toRefs(state),
             ...toRefs(findState),
             tofindCode,
             Back,
             updatacodeImg,
             saveCode,
             getPhoneCode
         }
    }
}
</script>
<style scoped lang="scss">
    .con{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-top: 70px;
        .tabs{
            display: flex;
            align-items: center;
            width: 420px;
            div{
                font-size: 20px;
                color: #999999;
                cursor: pointer;
                margin-right: 20px;
            }
            .active{
                font-weight: bold;
                color: #27282E !important;
            }
        }
        .form-fincode{
            width: 420px;
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            .input1{
                width: 420px;
                height: 50px;
                border: 1px solid rgba(221,221,221,1);
                margin-top: 20px;
                padding-left: 15px;
            }
            .code-con{
                display: flex;
                align-items: center;
                margin-top: 25px;
                .input2{
                    width: 305px;
                    margin-right: 15px;
                    height: 50px;
                    border: 1px solid rgba(221,221,221,1);
                    padding-left: 15px;
                }
                img{
                    width: 100px;
                    height: 46px;
                    border: 1px solid rgba(238,238,238,1);
                }
            }
            .mobcode-con{
                width: 420px;
                position: relative;
                margin-top: 20px;
                .input2{
                    width: 420px;
                    height: 50px;
                    border: 1px solid rgba(221,221,221,1);
                    padding-left: 15px;
                }
                .btn{
                    position: absolute;
                    right: 0;
                    top: 0;
                    color: rgb(2, 2, 2);
                    background: rgb(221, 221, 221);
                    width: 120px;
                    height: 50px;
                    background: rgba(184,184,184,1);
                    border: 0;
                    font-size: 16px;
                }
            }
            .form-login{
                background: #D4282D;
                color: #ffffff;
                font-size: 16px;
                font-weight: bold;
                cursor: pointer;
                margin-top: 40px;
                height: 50px;
                text-align: center;
                line-height: 50px;
                margin-bottom: 15px;
            }
        }
    }
    
</style>